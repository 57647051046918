import WIDGETS from './widgets';

export default {
	sx: {
		height: '100%',
	},
	spacing: 0,
	direction: 'column',

	widgets: [
		{ content: WIDGETS.GOBACKNAVIGATION, breakpoints: { xs: 12 }, lazy_loading: 0 },
		{ content: WIDGETS.NAVIGATION, breakpoints: { xs: 12 }, lazy_loading: 0, sx: { flexGrow: 1 } },
		// { content: WIDGETS.UNITS, breakpoints: { xs: 12 }, lazy_loading: 0 },
		{ content: WIDGETS.LOGOUT, breakpoints: { xs: 12 }, lazy_loading: 0 },
	],
};
