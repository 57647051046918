export const FETCH_TICKETS_DATA         = 'FETCH_TICKETS_DATA';
export const FETCH_TICKET               = 'FETCH_TICKET';
export const UPDATE_TICKET              = 'UPDATE_TICKET';
export const CHANGE_OVERVIEW_COMPONENT  = 'CHANGE_OVERVIEW_COMPONENT';
export const POST_TICKET                = 'POST_TICKET';
export const TICKET_PAYMENT             = 'TICKET_PAYMENT';
export const FETCH_CARE_TEAM            = 'FETCH_CARE_TEAM';
export const FETCH_TICKET_QUESTIONNAIRE = 'FETCH_TICKET_QUESTIONNAIRE';
export const TICKET_DIAGNOSIS           = 'TICKET_DIAGNOSIS';
export const SAVE_DIAGNOSIS             = 'SAVE_DIAGNOSIS';
export const FETCH_UNREAD_TICKETS_DATA  = 'FETCH_UNREAD_TICKETS_DATA';
export const FETCH_HISTORY_RECORD  = 'FETCH_HISTORY_RECORD';
export const SET_UNREAD_TICKET_DATA  = 'SET_UNREAD_TICKET_DATA';
export const CLEAR_TICKET_DATA  = 'CLEAR_TICKET_DATA';

