export const HANDLE_SPLASH_SCREEN = 'HANDLE_SPLASH_SCREEN';
export const HANDLE_LOADING = 'HANDLE_LOADING';
export const HANDLE_TABLE_LOADING = 'HANDLE_TABLE_LOADING';
export const MODAL_ACTION = 'MODAL_ACTION';
export const PROMPT_DIALOG_IS_DIRTY = 'PROMPT_DIALOG_IS_DIRTY';
export const LIGHTBOX_ACTION = 'LIGHTBOX_ACTION';
export const ZOOM_BUTTON_LIGHTBOX_ACTION = 'ZOOM_BUTTON_LIGHTBOX_ACTION';
export const DISABLE_FORM_BUTTON_ACTION = 'DISABLE_FORM_BUTTON_ACTION';
export const ADD_WRAPPER = 'ADD_WRAPPER';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const CHANGE_TABLE_PAGE = 'CHANGE_TABLE_PAGE';
export const SET_TABLE_COUNT = 'SET_TABLE_COUNT';
export const FETCH_ROLES = 'FETCH_ROLES';
export const DOCUMENT_PREVIEW = 'DOCUMENT_PREVIEW';
export const PROGRESS_ACTION = 'PROGRESS_ACTION';
export const SET_PROGRESS_ACTION = 'SET_PROGRESS_ACTION';
export const NOTIFICATIONS_MODAL_ACTION = 'NOTIFICATIONS_MODAL_ACTION';
export const HANDLE_BLOCKING_UI = 'HANDLE_BLOCKING_UI';
export const SET_TABLE_FILTERS = 'SET_TABLE_FILTERS';
export const SET_TABLE_CORE_STATE = 'SET_TABLE_CORE_STATE';
export const SET_TABLE_ROWS_PER_PAGE = 'SET_TABLE_ROWS_PER_PAGE';
export const SET_TABLE_FILTER_LIST = 'SET_TABLE_FILTER_LIST';
export const SET_TABLE_SEARCH = 'SET_TABLE_SEARCH';
export const PLAYER_ACTION = 'PLAYER_ACTION';
export const SIDE_BAR = 'SIDE_BAR';
export const MODAL_CATALOGUE = 'MODAL_CATALOGUE';
export const FETCH_LAYOUTS = 'FETCH_LAYOUTS';
export const FETCH_TAGGED_FILES  = 'FETCH_TAGGED_FILES';
export const FETCH_MY_FILES  = 'FETCH_MY_FILES';
export const FETCH_TAGS = 'FETCH_TAGS';
