import { bytesToMegaBytes } from '../../../helpers/functions/functions';
import { getTranslate } from 'react-localize-redux';
import { store } from '../../../store.js';

// export const generateValidation = validations => {
//   console.log(validations);
//   const errors = {}
//   if (!values.name) {
//     errors.name = 'Required'
//   } else if (values.name.length > 15) {
//     errors.name = 'Must be 15 characters or less'
//   }
//   if (!values.email) {
//     errors.email = 'Required'
//   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
//     errors.email = 'Invalid email address'
//   }
//   if (!values.age) {
//     errors.age = 'Required'
//   } else if (isNaN(Number(values.age))) {
//     errors.age = 'Must be a number'
//   } else if (Number(values.age) < 18) {
//     errors.age = 'Sorry, you must be at least 18 years old'
//   }
//   return errors
// }

const required = (value) =>
	(value && ((typeof value === 'string' && value.trim().length > 0) || (Array.isArray(value) && value.length > 0))) ||
	value instanceof Date ||
	Number.isInteger(value) ||
	(typeof value === 'boolean' && value)
		? undefined
		: 'form_field_required';

const minRequired = (min) => (value) => value >= min ? undefined : 'form_field_invalid';
const regexValidate = (regex) => (value) => typeof value === 'undefined' ? undefined : value.match(regex) ? undefined : 'form_field_invalid';
const functionValidation = (validationFunction, message) => (value) => validationFunction(value) ? undefined : message;
const email = (value) =>
	typeof value === 'undefined' || value === null || value.length === 0
		? undefined
		: value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g)
		? undefined
		: 'form_field_invalid_email';
const atLeastOneFieldRequired = (requiredFields, fieldLabel) => (value, allValues) => {
  const translate = getTranslate(store.getState().localize);
	if ((value && value.length > 0) || value instanceof Date || Number.isInteger(value)) {
		return undefined;
	} else {
		let flag = false;

		requiredFields.forEach((field) => {
			if (
				(allValues[field.id] && allValues[field.id].length > 0) ||
				allValues[field.id] instanceof Date ||
				Number.isInteger(allValues[field.id])
			) {
				flag = true;
			}
		});
		if (flag) {
			return undefined;
		} else {
			return (
				translate('form_field_at_least_one_field_required') +
				': ' +
				fieldLabel +
				', ' +
				requiredFields.map((field, index) => field.label + (index + 1 !== requiredFields.length ? ', ' : ''))
			);
		}
	}
};
const maxSizeFile = (max) => (value) => {
	if (value && value.length > 0 && bytesToMegaBytes(value[0].size) >= max.replace('M', ' ')) {
		return 'form_field_bigger_maxsize';
	} else {
		return undefined;
	}
};

const fileTypeReject = (file) => (value) => {
	if (value && value.length > 0 && (value[0].type === '' || value[0].type === file)) {
		return 'form_field_mime_type_invalid';
	} else {
		return undefined;
	}
};

// const videoResolutionsAllowed = (width, height) => (field, allowed_resolutions) => {
// 	let aspect_ok = false;

// 	allowed_resolutions.forEach((elem) => {
// 		if (width === elem[0] && height === elem[1]) {
// 			aspect_ok = true;
// 		}
// 	});

// 	if (!aspect_ok) {
// 		this.props.invalidateField(this.props.formData.form, {
// 			[field]: 'video_upload_error_resolution'),
// 		});
// 	}
// };

export const validate = (values) => (validations) => {
	const translate = getTranslate(store.getState().localize);

	const errors = {};
	let aux_error = '';
	Object.keys(validations).forEach((field) => {
		if (validations[field].required && (aux_error = required(values[field]))) {
			errors[field] = translate(aux_error);
		}
		if (validations[field].email && (aux_error = email(values[field]))) {
			errors[field] = translate(aux_error);
		}
		if (validations[field].minRequired && (aux_error = minRequired(validations[field].minRequired)(values[field]))) {
			errors[field] = translate(aux_error);
		}
		if (validations[field].regexValidate && (aux_error = regexValidate(validations[field].regexValidate)(values[field]))) {
			errors[field] = translate(aux_error);
		}
		if (validations[field].maxSizeFile && (aux_error = maxSizeFile(validations[field].maxSizeFile)(values[field]))) {
			errors[field] = translate(aux_error);
		}
		if (validations[field].fileTypeReject && (aux_error = fileTypeReject(validations[field].fileTypeReject)(values[field]))) {
			errors[field] = translate(aux_error);
		}
		if (
			validations[field].atLeastOneFieldRequired &&
			(aux_error = atLeastOneFieldRequired(validations[field].atLeastOneFieldRequired, field)(values[field], values))
		) {
			errors[field] = aux_error;
		}
		if (validations[field].functionValidation) {
			validations[field].functionValidation.forEach((f) => {
				if (!f.validation(values[field])) {
					errors[field] = translate(f.message ? f.message : 'form_field_invalid');
				}
			});
		}

		// if (validations[field].required) {
		//   required(values[field]);
		// }
		// if (validations[field].required) {
		//   required(values[field]);
		// }
		// if (validations[field].required) {
		//   required(values[field]);
		// }
		// if (validations[field].required) {
		//   required(values[field]);
		// }
	});

	// if (!values.name) {
	//   errors.name = 'Required'
	// } else if (values.name.length > 15) {
	//   errors.name = 'Must be 15 characters or less'
	// }
	// if (!values.email) {
	//   errors.email = 'Required'
	// } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
	//   errors.email = 'Invalid email address'
	// }
	// if (!values.age) {
	//   errors.age = 'Required'
	// } else if (isNaN(Number(values.age))) {
	//   errors.age = 'Must be a number'
	// } else if (Number(values.age) < 18) {
	//   errors.age = 'Sorry, you must be at least 18 years old'
	// }
	return errors;
};

export const warn = (values) => {
	const warnings = {};
	if (values.age < 19) {
		warnings.age = 'Hmm, you seem a bit young...';
	}
	return warnings;
};
