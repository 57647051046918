import PropTypes from 'prop-types';
import React, { Suspense, lazy, useMemo } from 'react';
import { catalogue_structure } from '../../catalogues';
import Grid from '@mui/material/Unstable_Grid2';
import ErrorBoundary from './ErrorBoundary';
import { CircularProgress } from '@mui/material';

function MemoizedLazyComponent({ content, lazy_loading, component_props }) {
	const LazyComponent = useMemo(() => {
		return lazy(async () => {
			const [moduleExports] = await Promise.all([import('../' + content), new Promise((resolve) => setTimeout(resolve, lazy_loading))]);
			return moduleExports;
		});
	}, [component_props]);

	return (
		<Suspense fallback={<CircularProgress size={20} />}>
			<LazyComponent {...component_props} />
		</Suspense>
	);
}

export const CatalogueGrid = ({ catalogue: { widgets = [], ...props } = { widgets: [] } }) => {
	return (
		<Grid container spacing={2} {...props}>
			{widgets.map((item, index) => (
				<Grid key={index} {...item.breakpoints} {...item.sx} {...item.grid_props}>
					{typeof item.content === 'string' ? (
						<ErrorBoundary>
							<MemoizedLazyComponent {...item} />
						</ErrorBoundary>
					) : (
						<CatalogueGrid catalogue={item.content} />
					)}
				</Grid>
			))}
		</Grid>
	);
};

CatalogueGrid.propTypes = {
	catalogue: PropTypes.shape(catalogue_structure),
	spacing: PropTypes.number,
};

export default CatalogueGrid;
